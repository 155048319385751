import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../providers/auth';
import stepOne from '../../assets/images/step-one.svg';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

function LandingPage(props) {
  const { setUserLite } = useContext(AuthContext);

  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    mobile: '',
    company: '',
    errors: {},
  });

  useEffect(() => {
    props.containerControls.setCardSize('-landing');
  }, []);

  const handleSubmit = (e) => {
    const errors = {};
    const fnameError = document.getElementById('fname-error');
    const lnameError = document.getElementById('lname-error');
    const pnumberErrorRequired = document.getElementById(
      'pnumber-error-required',
    );
    const pnumberErrorInvalid = document.getElementById(
      'pnumber-error-invalid',
    );

    if (!form.firstName) {
      errors.firstName = true;
      fnameError.classList.add('visible');
    }
    if (!form.lastName) {
      errors.lastName = true;
      lnameError.classList.add('visible');
    }
    if (!form.mobile) {
      errors.mobile = true;
      pnumberErrorRequired.classList.add('visible');
      pnumberErrorInvalid.classList.remove('visible');
    } else if (!isValidPhoneNumber(form.mobile)) {
      errors.mobile = true;
      pnumberErrorInvalid.classList.add('visible');
      pnumberErrorRequired.classList.remove('visible');
    }

    if (Object.keys(errors).length) {
      setForm({ ...form, errors });
    } else {
      setUserLite(form);
      window.scrollTo(0, -window.innerHeight);
    }
    e.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit} className="container-form">
      <h1>Instant QR</h1>
      <div className="step">
        <img src={stepOne} alt="Step One" />
        <h2>Fill in your details below to generate a QR code</h2>
      </div>
      <div className="container-input">
        <div className="input-form">
          <label htmlFor="first-name">First Name</label>
          <input
            type="text"
            autoComplete="on"
            id="first-name"
            className={form.errors.firstName ? 'error' : ''}
            value={form.firstName}
            onChange={(e) => setForm({ ...form, firstName: e.target.value })}
            maxLength={50}
          />
          <small id="fname-error">First name is required.</small>
        </div>
        <div className="input-form">
          <label htmlFor="last-name">Last Name</label>
          <input
            type="text"
            autoComplete="on"
            id="last-name"
            className={form.errors.lastName ? 'error' : ''}
            value={form.lastName}
            onChange={(e) => setForm({ ...form, lastName: e.target.value })}
            maxLength={50}
          />
          <small id="lname-error">Last name is required.</small>
        </div>
        <div className="input-form">
          <label htmlFor="mobile">Mobile</label>

          <PhoneInput
            autocomplete="off"
            placeholder="Enter mobile number"
            id="mobile"
            defaultCountry="AU"
            className={form.errors.mobile ? 'error' : ''}
            value={form.mobile}
            onChange={(mobile) => setForm({ ...form, mobile })}
            name="mobile"
            maxLength={30}
          />
          <small id="pnumber-error-invalid">Invalid mobile number.</small>
          <small id="pnumber-error-required">Mobile number is required.</small>
        </div>
        <div className="input-form">
          <label htmlFor="company">Company (optional)</label>
          <input
            type="text"
            autoComplete="organization"
            id="company"
            className={form.errors.company ? 'error' : ''}
            value={form.company}
            onChange={(e) => setForm({ ...form, company: e.target.value })}
            maxLength={50}
          />
          <small id="lname-error">Company name is required.</small>
        </div>
      </div>
      <p className="terms">
        By checking in you agree to our{' '}
        <a href="https://www.sine.co/terms/">Terms of Service</a> and{' '}
        <a href="https://www.sine.co/privacy/">Privacy Policy</a>
      </p>
      <div className="button-container">
        <button type="submit" href="#container-declaration">
          Next
        </button>
      </div>
    </form>
  );
}

export default LandingPage;
