import React from 'react';

import appStoreButton from '../assets/images/app-store-button.svg';
import googlePlayButton from '../assets/images/google-play-button.svg';

function SinePro() {
  const content = () => {
    if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      return (
        <a href="https://itunes.apple.com/au/app/sine-pro/id1086883094?mt=8">
          <img src={appStoreButton} alt="Download Sine Pro on App Store" />
        </a>
      );
    } else if (navigator.userAgent.match(/Android/i)) {
      return (
        <a href="https://play.google.com/store/apps/details?id=co.sine.sinecloud.pass&hl=en">
          <img src={googlePlayButton} alt="Download Sine Pro on Google Play" />
        </a>
      );
    } else {
      return <div>Not Mobile</div>;
    }
  };

  return (
    <div className="sine-pro">
      <p>Get Sine Pro for fast mobile check-in</p>
      {content()}
    </div>
  );
}

export default SinePro;
