import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../providers/auth';

function Declaration(props) {
  useEffect(() => {
    props.containerControls.setCardSize('-declaration');
  }, []);

  const { setDeclaration } = useContext(AuthContext);

  return (
    <>
      <div id="container-declaration" className="container-card">
        <h2>Before checking in, please confirm that:</h2>
        <div className="container-list">
          <ul>
            <li>
              You do not have any COVID-19-like symptoms, such as fever, cough,
              sore throat, or difficulty breathing
            </li>
            <li>
              You are not currently diagnosed with, or waiting for the result of
              a COVID-19 test
            </li>
            <li>
              You have not had close contact with someone diagnosed with
              COVID-19 in the last 14 days
            </li>
          </ul>
        </div>
        <div>
          <Link to="/notallowed">No</Link>
          <button onClick={setDeclaration} href="#container-qrcode">
            I confirm
          </button>
        </div>
      </div>
    </>
  );
}

export default Declaration;
