import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../providers/auth';
import VisitCode from './VisitCode';
import stepTwo from '../../assets/images/step-two.svg';

function QRPage(props) {
  const { user, clearUser } = useContext(AuthContext);

  useEffect(() => {
    props.containerControls.setCardSize('-qr');
  }, []);

  useEffect(() => {
    setTimeout(clearUser, 18 * 60 * 60 * 1000);
  }, [clearUser]);

  return (
    <>
      <div id="container-qrcode" className="container-card">
        <div className="step">
          <img src={stepTwo} alt="Step Two" />
          <h2>Scan this QR code on the iPad to check in</h2>
        </div>
        <VisitCode userType={user.userState} user={user.userLite} />
        <p className="reminder">
          Do you need to change your details?{' '}
          <Link onClick={clearUser} to="/">
            Change details
          </Link>
        </p>
      </div>
    </>
  );
}

export default QRPage;
