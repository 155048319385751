import React, { createContext, useEffect, useState } from 'react';

const USER_LITE_STORAGE_KEY = 'sine-lite-user';

function loadUserFromStorage() {
  let savedDetails;
  try {
    savedDetails =
      JSON.parse(localStorage.getItem(USER_LITE_STORAGE_KEY)) || {};
  } catch (e) {
    savedDetails = {};
  }
  return savedDetails;
}

function saveUserToStorage(user) {
  localStorage.setItem(USER_LITE_STORAGE_KEY, JSON.stringify(user));
}

const defaultAuthContext = {
  user: {
    userLite: {
      firstName: '',
      lastName: '',
      mobile: '',
      company: '',
    },
    userState: 'NO_USER',
  },
};

export const AuthContext = createContext(defaultAuthContext);

export default function AuthProvider(props) {
  const [user, setUser] = useState(defaultAuthContext.user);

  useEffect(() => {
    const { firstName, lastName, mobile, company } = loadUserFromStorage();
    if (firstName && lastName && mobile) {
      setUser({
        userLite: {
          firstName,
          lastName,
          mobile,
          company,
        },
        userState: 'LITE',
      });
    } else {
      setUser({
        userLite: {
          firstName: '',
          lastName: '',
          mobile: '',
          company: '',
        },
        userState: 'NO_USER',
      });
    }
  }, []);

  const setUserLite = ({ firstName, lastName, mobile, company }) => {
    setUser({
      userLite: {
        firstName,
        lastName,
        mobile,
        company,
      },
      userState: 'LITE',
    });

    return saveUserToStorage({ firstName, lastName, mobile, company });
  };

  const setDeclaration = () => {
    setUser({ ...user, userState: 'LITE2' });
    return saveUserToStorage();
  };

  const clearUser = () => {
    localStorage.removeItem(USER_LITE_STORAGE_KEY);
    setUser({
      userLite: {
        firstName: '',
        lastName: '',
        mobile: '',
        company: '',
      },
      userState: 'NO_USER',
    });
  };

  return (
    <AuthContext.Provider
      value={{ user, setUserLite, setDeclaration, clearUser }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}
