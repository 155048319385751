import React, { useEffect } from 'react';

import qrious from 'qrious';

function formatVisitCode({ firstName, lastName, mobile, company }) {
  return JSON.stringify({
    SineQR: {
      version: '1.0.0',
      webcheckin: {
        company,
        firstName,
        lastName,
        mobile,
      },
    },
  });
}

function VisitCode({ user }) {
  useEffect(() => {
    const width = document.getElementById('qr-container').clientWidth;
    new qrious({
      element: document.getElementById('qr'),
      value: formatVisitCode(user),
      size: width,
    });
  }, [user]);

  return (
    <div id="qr-container">
      <canvas id="qr"></canvas>
    </div>
  );
}

export default VisitCode;
