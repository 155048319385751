import React, { useState } from 'react';
import poweredSine from './assets/images/Powered-by-Sine.svg';
import './App.scss';
import SinePro from './components/SinePro';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import AuthProvider, { AuthContext } from './providers/auth';

import LandingPage from './pages/landing/LandingPage';
import Declaration from './pages/declaration/DeclarationCovid';
import QRPage from './pages/qr/QRPage';
import NotAllowed from './pages/notallowed/NotAllowed';

function CheckNavigator(props) {
  const isQR = props.isQR;
  if (
    isQR === 'LITE2' &&
    navigator.userAgent.match(/iPhone|iPad|iPod|Android/i)
  ) {
    return <SinePro />;
  }
  return null;
}

function App() {
  const [cardSize, setCardSize] = useState('large');

  const containerControls = {
    setCardSize,
  };

  return (
    <AuthProvider>
      <AuthContext.Consumer>
        {({ user }) => (
          <>
            <div className={`container container${cardSize}`}>
              <div className={`card${cardSize}`}>
                <Router>
                  <Switch>
                    <Route exact path="/">
                      {' '}
                      {user.userState === 'LITE' ? (
                        <Redirect to="/declaration" />
                      ) : user.userState === 'LITE2' ? (
                        <Redirect to="/qr" />
                      ) : (
                        <LandingPage containerControls={containerControls} />
                      )}
                    </Route>

                    <Route exact path="/declaration">
                      {user.userState === 'NO_USER' ? (
                        <Redirect to="/" />
                      ) : user.userState === 'LITE2' ? (
                        <Redirect to="/qr" />
                      ) : (
                        <Declaration containerControls={containerControls} />
                      )}
                    </Route>

                    <Route exact path="/notallowed">
                      {user.userState === 'NO_USER' ? (
                        <Redirect to="/" />
                      ) : user.userState === 'LITE2' ? (
                        <Redirect to="/qr" />
                      ) : (
                        <NotAllowed containerControls={containerControls} />
                      )}
                    </Route>

                    <Route path="/qr">
                      {user.userState === 'NO_USER' ? (
                        <Redirect to="/" />
                      ) : user.userState === 'LITE' ? (
                        <Redirect to="/declaration" />
                      ) : (
                        <QRPage containerControls={containerControls} />
                      )}
                    </Route>
                  </Switch>
                </Router>
              </div>
              <CheckNavigator isQR={user.userState} />
              <a href="https://www.sine.co">
                <img
                  className="powered-by-sine"
                  src={poweredSine}
                  alt="Powered by Sine"
                />
              </a>
            </div>
            <div className="background"></div>
          </>
        )}
      </AuthContext.Consumer>
    </AuthProvider>
  );
}

export default App;
