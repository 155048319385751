import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../../providers/auth';

function NotAllowed(props) {
  const { clearUser } = useContext(AuthContext);

  useEffect(() => {
    props.containerControls.setCardSize('-not-allowed');
  }, []);

  return (
    <>
      <div className="container-card">
        <h2>You may not be allowed to check in.</h2>
        <p>
          Please call or email your site contact and consider visiting at a
          later date.
        </p>
        <button onClick={clearUser}>Back</button>
      </div>
    </>
  );
}

export default NotAllowed;
